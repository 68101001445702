import { Autoplay, Pagination } from 'swiper';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/swiper.min.css';
import { Swiper } from 'swiper/vue/swiper-vue.js';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'StageCarousel',
    components: { Swiper },
    setup() {
        const swiperInstance = ref();
        const onSwiper = (swiper) => {
            swiperInstance.value = swiper;
            if (isElementInViewport(swiper.el)) {
                swiper.autoplay.start();
            }
            else {
                swiper.autoplay.stop();
            }
        };
        const isElementInViewport = (element) => {
            const rect = element.getBoundingClientRect();
            return (rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                    (window.innerHeight ||
                        document.documentElement.clientHeight) &&
                rect.right <=
                    (window.innerWidth || document.documentElement.clientWidth));
        };
        return {
            swiperInstance,
            isElementInViewport,
            onSwiper,
            modules: [Autoplay, Pagination],
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (this.swiperInstance) {
                if (this.isElementInViewport(this.swiperInstance.el)) {
                    this.swiperInstance.autoplay.start();
                }
                else {
                    this.swiperInstance.autoplay.stop();
                }
            }
        },
    },
});
